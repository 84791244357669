@import "./colors";
/**CampQuestions**/
.page-item.active .page-link {
  background-color: #c82638;
  border-width: 1px;
  border-color: #c82638;
}
/**CampQuestions**/

/*! custom css */
/*! ant-design-components */
.divider-vertical {
  background-color: black;
  font-size: 1.2rem;
  /* align-self: center; */
}
/*! letter spacing */
.ls-tighter {
  letter-spacing: -0.05em;
}
.ls-tight {
  letter-spacing: -0.025em;
}
.ls-normal {
  letter-spacing: 0em;
}
.ls-wide {
  letter-spacing: 0.025em;
}
.ls-wider {
  letter-spacing: 0.1em;
}
.ls-widest {
  letter-spacing: 0.2em;
}
.text {
  font-family: "Poppins", sans-serif !important;
}


.color-success {
  color: $success-green;
}
