.banner-container {
  height: 100%;
  background-color: #c82638;
}

.banner-text {
  line-height: 30px;
  /* text-align: center; Centers the text horizontally */
  margin: 0; /* Removes default margin */
  /* font-size: 24px; Adjust font size as needed */
  /* font-weight: bold; Adjust font weight as needed */
  color: #ffffff;
  /* background-color: #007bff; Adjust background color as needed */
}

/* .white-primary-button {
  background-color: #ffffff !important;
  color: #c82638 !important;
  border-color: #c82638 !important;
}

.white-primary-button:hover {
  background-color: #c82638 !important;
  color: #ffffff !important;
}

.white-primary-button:focus,
.white-primary-button:active {
  background-color: #c82638 !important;
  color: #ffffff !important;
  border-color: #c82638 !important;
} */

.white-primary-button {
  background-color: inherit !important;
  color: inherit !important;
  border-color: inherit !important;
}

.custom-button {
  background-color: white; /* White background */
  color: black; /* Black text color */
  border: 2px solid red; /* Red border */
}

.custom-button:hover {
  background-color: red; /* Change background color on hover */
  color: white; /* Change text color on hover */
}