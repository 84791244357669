@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

._slider-arrow {
  background-color: #c82638 !important;
  border-radius: 100% !important;
  color: white;
  border: none;
  padding: 7px;
  height: 35px;
  width: 35px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

:root {
  --primary-color: #c82638;
  --text-primary-color: #626161;
  --text-sub-color: #5d5c5c;
  --border: #5062772e;
  --h2: 40px;
  --h4: 20px;
}

html {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/*****************************
  Typography
*****************************/

body {
  background-color: #fff;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Poppins", sans-serif;
  color: #14141e;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}

p,
.p {
  margin-bottom: 0;
}

/* Custom Buttons */

.pt-5 {
  padding-top: 30px;
}

._theme-btn {
  background-color: #c82638 !important;
  border-color: #c82638 !important;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.btn .width-180 {
  width: 180px !important;
  height: 35px !important;
}

.ant-md-btn {
  width: 140px !important;
  height: 40px !important;
}

._mb-5 {
  margin-bottom: 5px !important;
}

._btn-default {
  height: 45px;
  min-width: 120px;
  background-color: #c82638;
  color: #fff;
  font-size: 16px;
  line-height: 23px;
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: 500;
  letter-spacing: 1px;
}

._btn-default:hover {
  color: #fff;
  background-color: #ea172f;
}

.btnDefault {
  height: 35px;
  min-width: 80px;
  background-color: #c82638;
  color: #fff;
  font-size: 14px;
}

.btnDefault:hover {
  color: #fff;
  background-color: #ea172f;
}

._btnRound {
  border: none;
  color: #fff;
  font-weight: 400;
  background: #c82638;
  border-radius: 50px;
  padding: 9px 30px;
  margin-bottom: 10px;
  font-size: 16px;
  min-width: 150px;
  letter-spacing: 0.3px;
  position: absolute;
  bottom: 5px;
  right: 20px;
  z-index: 99;
}

._btnRound:hover {
  background: #ea172f;
  color: #fff;
}

.icon-holder-txt i {
  display: inline-block;
  padding-right: 8px;
  color: #c82638;
}

.coach_name {
  padding-left: 25px;
  font-size: 15px;
  color: #333;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
}

/* Custom Backgrounds */

._bg-theme {
  background-color: #c82638 !important;
}

._bg-theme-light {
  background-color: #c8263854 !important;
}

/* Custom Colors */

._text-white {
  color: #fff !important;
}

._text-theme {
  color: #c82638 !important;
}

._text-dark {
  color: #14141e !important;
}

._hvr-underline:hover {
  text-decoration: underline !important;
}

._btnGrp a:not(:last-child),
._btnGrp .btn:not(:last-child) {
  margin-right: 10px !important;
}

/* Side Menu */

body[data-sidebar="dark"] .vertical-menu,
.vertical-menu {
  background: rgba(200, 38, 56, 1) !important;
}

body[data-sidebar="dark"] .navbar-brand-box,
.navbar-brand-box {
  background: rgba(200, 38, 56, 1) !important;
}

body[data-sidebar="dark"] .menu-title {
  color: rgba(255, 255, 255, 0.8);
}

body[data-sidebar="dark"] #sidebar-menu ul li a,
body[data-sidebar="dark"] #sidebar-menu ul li a i {
  color: #ffffffd6 !important;
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover,
body[data-sidebar="dark"] #sidebar-menu ul li a:hover i {
  color: #fff !important;
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a {
  color: #ffffffb0 !important;
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #fff !important;
}

#sidebar-menu ul li a {
  padding: 0.625rem 1.2rem !important;
}

#sidebar-menu {
  padding-top: 60px !important;
}

.navbar-pro-info>span {
  display: block;
  line-height: 13px;
  font-size: 12px;
}

.navbar-pro-info ._text-white.pt-2 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.navbar-header .navbar-right-box {
  align-items: center;
}

.dropdown-menu.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.page-title-box h4 {
  color: #343a40 !important;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 17px !important;
}

._sectionPadding {
  padding-top: 50px;
  padding-bottom: 50px;
}

._cardTop-hdr h2 {
  color: #022d62 !important;
  font-size: 30px;
}

._bookingTable-outter {
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 8px 0px rgb(2 45 98 / 10%);
}

._list-outter:not(:last-child) {
  margin-bottom: 30px;
}

._bSearchDiv:not(:last-child) {
  margin-bottom: 30px;
}

._bSearchDiv .form-control {
  height: 45px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px rgb(2 45 98 / 10%);
  background-image: url(../images/search.png);
  background-repeat: no-repeat;
  background-position: 18px center;
  padding-left: 50px;
  background-blend-mode: exclusion;
  background-size: 19px;
}

._clientLists {
  padding: 0;
}

._clientLists:last-child {
  margin-bottom: 0;
}

._clientList {
  list-style: none;
}

._clientList:not(:last-child) {
  /* padding-bottom: 10px; 
    margin-bottom: 10px;*/
  /* border-bottom: 1px solid #f0f0f0; */
}

._b-label {
  position: relative;
  display: block;
  margin: 0;
  cursor: pointer;
}

._b-label input[type="checkbox"],
._b-label input[type="radio"] {
  position: absolute;
  opacity: 0;
}

._clientList-div {
  position: relative;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
}

._clientList-div ._clCont ._name {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #14141e;
  text-transform: capitalize;
}

._b-label:hover ._name {
  color: #c82638;
}

._b-label input:checked~._clientList-div ._name,
._b-label input:checked~._clientList-div ._info {
  color: #fff;
}

/* ._b-label input:checked ~ ._clientList-div ._name,
._b-label:hover ._name  {
    color: #c82638;
} */

._clientList-div ._proPic~._clCont {
  padding-left: 80px;
}

._clientList-div ._clCont ._info:last-child {
  margin-bottom: 0;
}

._clientList-div ._clCont ._info {
  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 3px;
  color: #676c71;
}

._clientList-div ._proPic {
  height: 55px;
  width: 55px;
  overflow: hidden;
  display: inline-block;
  border: 1px solid #676c71;
  border-radius: 100%;
  position: absolute;
  left: 15px;
  background-color: #676c71;
}

._b-label input:checked~._clientList-div {
  background-color: rgba(200, 38, 56, 0.9);
}

._b-label input:checked~._clientList-div ._proPic {
  box-shadow: 0 0px 0px 2px #fff;
  border-color: #fff;
}

._b-label input:checked~._clientList-div ._proPic {
  box-shadow: 0 0px 0px 2px #c82638;
  border-color: #c82638;
}

._clientList-div ._proPic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

._clientProDiv {
  position: relative;
  min-height: 75px;
  display: flex;
  align-items: center;
}

._clientProDiv:not(:last-child) {
  margin-bottom: 15px;
}

._clientProDiv ._proPic {
  height: 75px;
  width: 75px;
  overflow: hidden;
  display: inline-block;
  border: 1px solid #676c71;
  border-radius: 100%;
  position: absolute;
  left: 0;
  background-color: #676c71;
}

._clientProDiv ._proPic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

._clientProDiv ._proPic~._clCont {
  padding-left: 95px;
}

._clientProDiv ._clCont ._name {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
  margin-bottom: 3px;
  color: #14141e;
  text-transform: capitalize;
}

._clientProDiv ._clCont ._info {
  font-size: 13px;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 3px;
  color: #676c71;
}

._clientProDiv ._clCont ._info:last-child {
  margin-bottom: 0;
}

._bookingDetails p {
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

._bookingDetails p:not(:last-child) {
  margin-bottom: 5px;
}

/* Time List */

._timeListDiv {
  position: relative;
}

._timeList {
  padding: 0;
  margin-top: 5px;
  margin-right: -2.5px;
  margin-left: -2.5px;
  display: flex;
  flex-wrap: wrap;
}

._timeList li {
  display: inline-block;
  list-style: none;
  padding-left: 2.5px;
  padding-right: 2.5px;
}

._timeList li label,
._timeList li ._t-label {
  display: block;
  cursor: pointer;
  margin-bottom: 5px;
}

._timeList li label input[type="checkbox"],
._timeList li label input[type="radio"],
._timeList li ._t-label input[type="checkbox"],
._timeList li ._t-label input[type="radio"] {
  position: absolute;
  opacity: 0;
}

._timeSpan {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 5px 13px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  color: #676c71;
}

._timeList li label input:checked~._timeSpan,
._timeList li ._t-label input:checked~._timeSpan {
  border-color: #ec1830;
  background-color: #ec1830;
  color: #fff;
}

._timeSpan._selected {
  border-color: #ec1830;
  background-color: #ec1830;
  color: #fff;
}

._ctimeDiv:not(:last-child) {
  margin-bottom: 20px;
}

._ctimeDiv {
  display: none;
}

._ctimeDiv.show {
  display: block;
}

._finalDate {
  position: relative;
  margin-top: 15px;
  display: block;
}

._finalDate p {
  font-size: 16px;
  font-weight: 700;
  color: #333;
}

._finalDate p:last-child {
  margin-bottom: 0;
}

._finalDate ._icon~p {
  padding-left: 45px;
}

._finalDate ._icon img {
  max-width: 25px;
  max-height: 30px;
  object-fit: contain;
}

._finalDate ._icon {
  position: absolute;
  top: -2px;
  width: 30px;
  text-align: center;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8f8fb;
  border: 1px solid #f6f6f6;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.profile-user-wid {
  margin-top: -36px;
}

/* One to one Booking  */

._bHdr {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px !important;
  align-items: center;
}

._bHdr-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 0;
  margin-left: auto;
}

._daySeclectionDiv:not(:last-child) {
  margin-right: 30px;
}

._daySeclectionDiv ._btn-round-small {
  min-width: 80px;
}

._btn-round-small._active,
._btn-round-small:hover,
._booked {
  background-color: #c82638;
  color: #fff;
}

._calenderDiv .form-control {
  padding: 10px 20px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  background: #f6f6f6;
  color: #022d62;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #c82638;
  height: 40px;
}

._bookingTable-outter {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 8px 0px rgb(2 45 98 / 10%);
}

._dateBookingTable td {
  padding: 10px 25px;
  border: none;
  border-bottom: 1px solid #f0f0f0;
}

._dateBookingTable tbody>tr td:first-child {
  width: 150px;
}

._boldTxt {
  font-size: 18px;
  font-weight: 700;
  color: #c82638;
}

._dateBookingTable ._lgTxt {
  font-size: 30px;
}

._smTxt {
  font-size: 11px;
  line-height: 15px;
}

._dateBookingTable .text-dark {
  font-size: 17px;
}

._dateBookingTable ._smTxt {
  font-weight: 700;
  color: #676c71;
  font-size: 13px;
}

._dateBookingTable-inner {
  margin-bottom: 0;
}

._dateBookingTable-inner td {
  vertical-align: middle;
  border: none;
}

._dateBookingTable-inner p:last-child {
  margin-bottom: 0;
}

._dateBookingTable-inner tr td:nth-child(2) {
  width: 135px;
  text-align: center;
}

._signBtn {
  background-color: #c82638d4 !important;
  font-size: 13px;
  padding: 6px 20px !important;
  border-radius: 5px;
  color: #fff;
  text-transform: capitalize;
}

._signBtn:hover {
  background-color: #c82638;
  color: #fff;
}

._btn-round-small {
  background-color: #c8263838;
  display: inline-block;
  font-size: 12px;
  padding: 8px 15px;
  color: #000;
}

/* Class Booking Page */

._fiterTopBar {
  padding: 10px 0;
  /* background: #eee; */
}

._fiterTopBar .row {
  align-items: center;
}

._dateRange {
  position: relative;
  padding: 0 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}

._dateRange>p {
  align-items: center;
  justify-content: center;
}

._dateRange p {
  margin-bottom: 0;
  font-size: 14px;
  color: #333;
  margin-left: 70px;
}

._dateRange span {
  display: inline-block;
  padding: 0 5px;
}

._calenderSlider-div {
  position: relative;
  margin-left: 30px;
}

._calenderSlider-div ._dateRange {
  margin-top: 0;
}

._calenderSlider-div .owl-carousel .owl-next,
._calenderSlider-div .owl-carousel .owl-prev {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  height: 30px !important;
  width: 30px !important;
  background-color: #c82638 !important;
  border-radius: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
}

._calenderSlider-div .owl-carousel .owl-prev {
  left: 0 !important;
}

._calenderSlider-div .owl-carousel .owl-next {
  right: 0 !important;
  left: auto !important;
}

._filterGrp .btn {
  height: unset;
  padding: 5px 15px;
  min-width: unset;
  font-size: 13px;
  line-height: 22px;
  background-color: #343a40;
  color: #fff;
}

._filterGrp .btn:hover,
._filterGrp .btn._active {
  background-color: #c82638;
  color: #fff;
}

/* Dashboard */

._dashboardPage .btn-primary {
  color: #fff;
  background-color: #c72638;
  border-color: #c72638;
  text-align: center;
}

._packageLists {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

._package {
  max-width: 49%;
  padding-left: 5px;
  padding-right: 5px;
}

._package .card {
  margin-bottom: 15px;
}

._package label {
  position: relative;
  display: block;
  margin: 0;
  cursor: pointer;
}

._package label input[type="checkbox"],
._package label input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

._package label input:checked~.card {
  background-color: rgba(200, 38, 56, 0.9) !important;
}

._filterGrp.text-right {
  text-align: end;
}

.tui-full-calendar-confirm {
  background-color: #c72638;
  opacity: 0.8;
}

.tui-full-calendar-confirm:hover {
  background-color: #c72638;
  color: #fff;
  opacity: 1;
}

.avatar-title {
  overflow: hidden;
}

.avatar-title img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.rounded-circle span.avatar-title {
  border-radius: 100%;
  margin: 0 auto 20px;
}

._up-eventWrapper p {
  font-size: 12px;
  line-height: 16px;
}

._modal-close {
  position: absolute;
  height: 25px;
  width: 25px;
  border: 2px solid #fff;
  background-color: #fff;
  color: #fff;
  right: -35px;
  top: -35px;
  opacity: 1;
  border-radius: 100%;
}

._succes-gif {
  display: block;
  text-align: center;
  margin-bottom: 10px;
  max-height: 100px;
}

._msgModal .modal-dialog {
  width: 350px;
  max-width: 100%;
}

._msgModal .modal-body.text-center {
  padding: 30px 15px;
}

._succes-gif img {
  height: 100%;
  max-height: 100px;
}

._succesTitle {
  font-size: 25px;
  color: green;
}

._clientProDiv p._name._title-3 {
  font-size: 17px;
  line-height: 25px;
}

._btnLists {
  margin-top: 20px;
}

._btnLists .btn {
  width: 100%;
  display: block;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

._btnLists .btn:hover {
  color: #fff;
}

._btnLists.inline {
  width: 100%;
}

._btnLists.inline .btn {
  width: auto;
  display: inline-block;
  margin: 0 5px;
}

.orderCard .card-header {
  color: #000;
  letter-spacing: 1px;
}

.orderCard p {
  color: #495057;
  font-weight: 500;
}

[data-bs-toggle="modal"] {
  cursor: pointer;
}

._up-eventWrapper .table-responsive::-webkit-scrollbar-track,
.divScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

._up-eventWrapper .table-responsive::-webkit-scrollbar,
.divScroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

._up-eventWrapper .table-responsive::-webkit-scrollbar-thumb,
.divScroll::-webkit-scrollbar-thumb {
  background-color: #c72638;
}

._up-eventWrapper .table-responsive {
  max-height: 300px;
}

._finalDateLarge._finalDate ._icon~p {
  padding-left: 95px;
}

._finalDateLarge._finalDate ._icon {
  width: 75px;
}

._finalDateLarge._finalDate ._icon img {
  max-width: 55px;
  max-height: 80px;
}

._finalDateLarge._finalDate ._largetxt {
  font-size: 20px;
}

._finalDateLarge {
  position: relative;
}

._bookingDetails {
  position: relative;
}

.timeEdit {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  font-size: 20px;
  line-height: 16px;
  background: #c82638;
  color: #fff;
  padding: 4px 4px;
  border-radius: 35px;
  height: 35px;
  width: 35px;
  opacity: 0.8;
}

.timeEdit:hover {
  opacity: 1;
}

._editDiv.hide {
  display: none;
}

.calendar-row .btn-primary {
  color: #fff;
  background-color: #c82638;
  border-color: #c82638;
}

.booking-option-modal .modal-body {
  padding: 45px 15px;
}

.booking-option-modal .button-items .btn {
  display: inline-flex;
  font-size: 15px;
}

.booking-option-modal .button-items .btn i {
  font-size: 20px;
  margin-right: 5px;
}

.booking-option-modal .btn-danger:hover {
  background-color: #c82638;
}

.booking-option-modal .btn-success:hover {
  background-color: #15845b;
}

._bookingTable-outter .divScroll {
  max-height: 600px;
  overflow-y: scroll;
}

._bHdr .app-search .form-control {
  border: 1px solid #d2cdcd;
}

.clientCard {
  position: relative;
  border: 1px solid #fff;
  transition: all 0.5s;
}

.clientCard:hover {
  border-color: #c72638;
  transform: translateY(-5px);
}

.avName {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #eee;
  font-size: 17px;
  color: #333;
}

.clientBox .avName img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  border-radius: 100%;
}

.clientCard .proName {
  position: absolute;
}

.clientBox {
  position: relative;
}

.clientBox .avName {
  position: absolute;
  left: 0;
  top: -5px;
}

.clientBox {
  min-height: 55px;
}

.clientBox .avName~._clCont {
  padding-left: 65px;
}

.clientBox ._name {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #c82638;
}

.clientCard .card-body {
  padding: 20px 15px;
}

._info {
  font-size: 13px;
  margin: 0;
  color: #333;
}

.iconBtn {
  display: inline-flex;
  align-items: center;
}

.iconBtn i {
  font-size: 15px;
  padding-right: 5px;
  display: inline-block;
}

.selectWrap {
  display: flex;
  align-items: center;
}

.fIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: #c82638;
  padding: 5px;
  border-radius: 100%;
  margin-right: 5px;
}

.fIcon img {
  max-width: 15px;
}

.selectWrap label {
  align-items: center;
  display: flex;
  font-size: 16px;
  color: #333;
  margin-right: 15px;
}

.vidIcon {
  position: absolute;
  left: 0;
  top: 0;
}

.vidIcon img {
  width: 25px;
}

.vidIcon~._clCont {
  padding-left: 35px;
}

._info-2 {
  color: #343a40 !important;
  font-size: 15px;
  font-weight: 500;
}

/* Chat Board  */

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #c82638;
}

.chatBoard .bg-primary.bg-soft {
  background-color: rgba(200, 38, 56, 0.25) !important;
  color: #c82638 !important;
}

.chatBoard .chat-conversation .conversation-list .ctext-wrap {
  background-color: #f1f1f18f !important;
}

.chatBoard .chat-conversation .conversation-list .ctext-wrap .conversation-name,
.chatBoard .chat-conversation .last-chat .conversation-list:before {
  color: #c82638 !important;
}

.chatBoard ._bg-theme {
  border-color: #c72638;
}

.chat-input-section .btn-primary {
  color: #fff;
  background-color: #c82638cf;
  border-color: #c62639;
}

.chat-input-section .btn-primary:hover {
  background-color: #c72638;
  border-color: #c72638;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper .btn {
  color: #d24f5d;
  background-color: transparent;
  padding: 0;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  cursor: pointer;
  height: 18px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.vertical-menu>div {
  overflow: hidden scroll;
}

.vertical-menu>div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #c72638;
  box-shadow: inset 0 0 6px #c72638;
  background-color: #c72638;
}

.vertical-menu>div::-webkit-scrollbar {
  width: 6px;
  background-color: #c72638;
}

.vertical-menu>div::-webkit-scrollbar-thumb {
  background-color: #c72638;
}

.navbar-header button#page-header-user-dropdown {
  display: flex;
  align-items: center;
}

.navbar-header .avName {
  height: 45px;
  width: 45px;
}

._dateBookingTable ._smTxt,
._dateBookingTable ._lgTxt {
  padding: 0;
}

.class-info-modal .modal-dialog {
  max-width: calc(100% - 30px);
  width: 500px;
}

.class-info-modal .modal-dialog .modal-content {
  /* overflow: hidden; */
}

.modal-content-inner {
  max-height: 90vh;
  overflow-y: scroll;
  width: calc(100%);
}

.modal-content-inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  box-shadow: inset 0 0 6px white;
  background-color: white;
}

.modal-content-inner::-webkit-scrollbar {
  width: 6px;
  background-color: black;
}

.modal-content-inner::-webkit-scrollbar-thumb {
  background-color: #c82638;
}

.classInfoBlock figure img {
  max-height: 135px;
  width: 100%;
  object-fit: cover;
}

.classInfoBlock .service {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.date-time-div {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-transform: uppercase;
  color: #333;
  font-weight: 700;
  letter-spacing: 1px;
}

.date-time-div>div:not(:last-child) {
  padding-right: 25px;
}

.date-time-div>div {
  display: flex;
  align-items: center;
}

.date-time-div ._icon {
  display: flex;
  align-items: center;
}

.date-time-div ._icon img {
  max-height: 25px;
  margin-right: 10px;
}

.date-time-div ._icon i {
  font-size: 25px;
  line-height: 20px;
  color: #c82638;
  margin-right: 5px;
}

.cinfo-div-outter .cinfo-div {
  border: 1px solid #dddddd91;
}

.cinfo-div-outter .cinfo-div:last-child {
  margin-right: 0;
}

.cinfo-title {
  display: block;
  padding: 5px;
  background-color: #000000d1;
  font-size: 12px;
  color: #fff;
}

.cinfo {
  display: block;
  padding: 6px;
  text-transform: capitalize;
  color: #333;
  font-weight: 500;
}

.cinfo-title i {
  padding-right: 1px;
}

.classInfoBlock .desc {
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: bold;
  text-transform: capitalize;
  color: #333;
}

/* star, css */
.desc-normal {
  color: #4f5262;
  white-space: pre-line;
  line-height: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: #495057;
}

.zoomLink {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-transform: capitalize;
  color: #333;
  font-weight: 600;
  letter-spacing: 1px;
}

.zoomLink p {
  margin-bottom: 0;
}

.iconRound {
  height: 33px;
  width: 33px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #c82638;
  font-size: 20px;
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
}

#page-topbar {
  box-shadow: 0 0 10px rgb(0 0 0 / 5%) !important;
  position: unset !important;
}

.booked-info {
  /* background: #fff; */
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #dddddd4f;
  /* box-shadow: 0 0 2px 10px rgb(0 0 0 / 5%); */
  box-shadow: 0 0 5px 0px rgb(0 0 0 / 5%);
}

.booked-info .info {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-weight: 500;
}

.booked-info .info i {
  color: #c82638;
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -2px;
}

.booked-info .info.date {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-transform: uppercase;
  color: #c82638;
  font-weight: 700;
  letter-spacing: 1px;
}

.booked-info .trainer.info i {
  top: 1px;
}

.booked-wrapper .btns-div .btn {
  background-color: #c82638;
  color: #fff;
}

.view-det {
  opacity: 0.9;
}

.view-det:hover {
  opacity: 1;
}

.booked-btn {
  cursor: auto !important;
}

.ex-logo {
  max-height: 60px;
  max-width: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.card.eventCard .card-body {
  padding: 15px 20px;
  min-height: 345px;
  max-height: 345px;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 345px;
  max-height: 345px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.eventCard .card-title.mb-4 {
  margin-bottom: 15px !important;
}

.welcome_card .text-primary.p-3 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.book-now-card .card-body {
  padding-top: 25px;
  padding-bottom: 25px;
}

.card.welcome_card.overflow-hidden {
  margin-bottom: 26px;
}

body[data-sidebar="dark"].vertical-collpsed {
  min-height: 100vh !important;
}

.main-content._dashboardPage .page-content {
  padding-bottom: 115px !important;
  padding-top: 0px !important;
}

.main-content._dashboardPage .page-content {
  padding-bottom: 115px !important;
  padding-top: 0px !important;
}

.page-content {
  padding-bottom: 115px !important;
  padding-top: 50px !important;
}

.footer {
  width: 100%;
  left: 0 !important;
  text-align: center;
  bottom: 0;
}

.page-title-box {
  padding-top: 10px;
}

.calender-card {
  overflow: hidden;
}

.list_grid {
  background-color: transparent;
  box-shadow: none;
}

.list_grid ._clientLists {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.list_grid ._clientLists ._clientList {
  flex: 0 0 auto;
  width: 33%;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  margin-bottom: 10px;
  margin: 0 auto;
}

.list_grid ._clientLists ._clientList ._clientList-div {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 8px 0px rgb(2 45 98 / 10%);
  min-height: 125px;
}

.list_grid ._b-label input:checked~._clientList-div {
  background-color: rgba(200, 38, 56, 0.9) !important;
}

.list_grid ._clientList-div ._clCont ._name {
  font-size: 20px;
}

.list_grid ._clCont p {
  position: relative;
  padding-left: 20px;
}

.list_grid ._clCont p i {
  position: absolute;
  left: 0;
  font-size: 16px;
  top: 4px;
}

.list_grid ._clCont ._name {
  margin-bottom: 6px;
}

.list_grid ._clCont ._name i {
  font-size: 17px;
  top: 1px;
  left: -2px;
}

.appt-booking-modal ._btnLists {
  display: flex;
  align-items: center;
  justify-content: center;
}

.appt-booking-modal ._btnLists .btn {
  width: auto;
  margin: 0 5px;
  border-radius: 5px;
}

.appt-booking-modal ._clientProDiv {
  flex-direction: column;
}

.appt-booking-modal ._clientProDiv ._proPic {
  position: relative;
  margin-bottom: 15px;
}

.appt-booking-modal ._clientProDiv ._proPic~._clCont {
  padding-left: 00;
  text-align: center;
}

.btn.booked-btn:empty {
  display: none;
}

.appt-booking-modal ._clientProDiv ._proPic {
  position: relative;
  margin-bottom: 15px;
  border: 3px solid #423839;
}

.appt-booking-modal ._finalDate p {
  padding-left: 25px;
  font-size: 13px;
  text-transform: uppercase;
  color: #c82638;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  display: inline-block;
  margin-bottom: 4px;
}

.appt-booking-modal ._finalDate p i {
  font-size: 16px;
  position: absolute;
  left: 0;
}

.booking_btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.booking_btns .btn {
  margin: 0 5px;
}

.btn-round {
  height: 45px;
  width: 45px;
  min-width: unset;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c82638;
  opacity: 0.9;
  color: #fff;
}

.btn-round:hover {
  opacity: 1;
  color: #fff;
}

.btn-primary {
  background-color: #c82638 !important;
  border-color: #c82638 !important;
}

.appt-booking-modal ._clientProDiv ._clCont ._name {
  font-size: 18px;
}

.dropdown-menu .dropdown-item {
  cursor: pointer;
}

body.vertical-collpsed .main-content {
  margin-left: 0;
}

.user-dropdown {
  margin-left: 5px;
  font-size: 12px;
}

.booked_table .booked-info ._smTxt.date {
  display: inline-block;
  margin-right: 10px;
}

.booked_table .booked-info .info.text-dark.trainer {
  display: inline-block;
}

.booked_table .booked-info ._smTxt {
  display: inline-block;
  margin-right: 10px;
}

.booked_table .btn.view-det {
  border: 1px solid #c82638;
  border-radius: 25px;
  color: #c82638;
  padding: 8px 25px;
}

.booked_table .desc-div {
  margin-bottom: 10px;
}

.limit-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* @customAdd: fix number of lines: */
.desc-p {
  display: -webkit-box;
  max-height: 2.8em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.booked_img {
  position: relative;
}

#pointer {
  width: 90px;
  height: 26px;
  position: absolute;
  background: green;
  padding: 4px 9px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  top: 10px;
  right: 0px;
}

#pointer::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 0px solid white;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
}

#pointer::before {
  content: "";
  position: absolute;
  left: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 20px solid green;
  border-top: 12px solid transparent;
  border-bottom: 14px solid transparent;
}

.left_td span {
  display: block;
  text-align: center;
}

.booked_table td {
  padding: 20px 0px;
}

.booked_details {
  display: block;
}

.left_td {
  border-right: 1px solid #eee !important;
}

.account-pages .tel-input.form-control {
  width: 100%;
}

header .navbar-header {
  height: 80px;
}

.credit-card-box {
  width: 450px;
  margin: 0 auto;
}

.credit-card-box .sq-payment-form {
  width: 100%;
}

.credit-card-box .sq-form-third {
  width: calc((100% - 17px) / 2);
}

/* 25-06-2021 start */

.sign_in .nav-tabs,
.sign_in .nav-item {
  border: 0;
}

.sign_in .nav-tabs .nav-item.active {
  border-bottom: 2px solid #c82638;
}

.sign_in .nav-tabs .nav-item {
  color: #495057;
  position: relative;
}

.sign_in .nav-tabs {
  margin-left: 50px;
}

.sign_in .nav-tabs .nav-item.active::before {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #c82638;
  left: 50%;
  position: absolute;
  bottom: 0;
  content: "";
  transform: translateX(-50%);
}

.booking_page .card.selected {
  box-shadow: 0 0.125rem 0.25rem #343a40 !important;
}

/* 25-06-2021 end */

/* 02-07-2021 */
.box img {
  width: 100%;
  height: 100%;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 9;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #333;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 17px 0;
  background-color: #1ba717;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 600 14px/1 "Poppins", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* 02-07-2021 */

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox+label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.styled-checkbox+label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #ddd;
}

.styled-checkbox:hover+label:before {
  background: #f35429;
}

.styled-checkbox:focus+label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
  background: #f35429;
}

.styled-checkbox:disabled+label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled+label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked+label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 11px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.members .del .fa {
  font-size: 19px;
}

.order_summery {
  width: 400px;
  margin: 0 auto;
}

.otp_box .inputStyle {
  width: 40px !important;
  height: 40px;
  margin: 0 8px;
  font-size: 25px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

td.booked_details {
  position: relative;
}

.sq-creditcard {
  background: #dc3545;
}

.sq-creditcard:hover {
  background: #c82638;
}

/* 08-07-2021 */
.dropdown-menu .dropdown-item.active {
  background: #eee;
}

.dropdown-menu .dropdown-item.active::before {
  content: "\f00c";
  float: right;
  font-family: fontawesome;
  color: green;
  position: absolute;
  right: 15px;
}

.company.dropdown-menu {
  width: 380px;
  height: 320px;
  overflow-y: auto;
  top: 75px !important;
}

/* .mult-address address strong {
  line-height: 60px;
} */
.second-dropdown .company.dropdown-menu {
  top: 58px !important;
}

#company_dropdown {
  background: transparent !important;
  border: 0 !important;
  /* width: 24px; */
}

#company_dropdown.btn-primary:focus {
  box-shadow: none !important;
}

.company_logo .logo.logo-light,
.company_logo .logo.logo-light .logo-sm {
  display: inline-block;
}

#company_dropdown .user-dropdown {
  font-size: 17px;
  color: #555b6d;
}

/* 08-07-2021 */

/* 12-07-21 */

.cat-drop-box .dropdown-menu.show {
  width: 300px;
  max-height: 300px;
  overflow: auto;
}

/* 12-07-21 */

/* explore details */

.preview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media screen and (max-width: 996px) {
  .preview {
    margin-bottom: 20px;
  }
}

.preview-pic {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 260px;
  max-height: 260px;

  &.small {
    max-width: 160px;
    max-height: 160px;
  }
}

.preview-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

/* .tab-content {
  overflow: hidden;
}
.tab-content img {
  width: 100%;
  -webkit-animation-name: opacity;
  animation-name: opacity;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
} */

@media screen and (min-width: 997px) {
  .wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .container.favourite-item-list .col-md-4 {
    width: 50% !important;
  }

  .company_logo .logo .logo-sm {
    width: 60px;
  }
}

.details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.colors {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.product-title {
  text-transform: UPPERCASE;
  font-weight: bold;
}

.checked {
  color: #ff9f1a;
}

.view-tabs .nav-tabs .nav-link {
  color: #495057;
  position: relative;
  border: 0px;
}

.view-tabs .nav-tabs .nav-link.active {
  border-bottom: 2px solid #c82638;
}

.view-tabs .nav-tabs .nav-link.active:before {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #c82638;
  left: 41px;
  position: absolute;
  top: 27px;
  content: "";
}

.view-tabs .nav-tabs {
  border-bottom: 0px;
}

.favourite-search input {
  height: 37px;
}

/* explore details */
.companyName {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: #eee;
  font-size: 5rem;
  color: #333;
  font-weight: bold;
}

.coaches-three .coaches-image {
  float: left;
  margin: 0 20px 0 0;
  width: 80px;
  height: 80px;
  border-radius: 100px;
  overflow: hidden;
}

.coaches-content {
  overflow: hidden;
}

.branches .item {
  border: 1px solid #eee;
  padding: 15px;
}

.coaches-content p {
  margin-bottom: 0;
}

.coaches-content p a {
  color: #c82638;
}

.coaches-content .coaches-name {
  margin-bottom: 10px;
  display: block;
  font-size: 15px;
}

.coaches-three .coaches-image img {
  max-width: 100%;
  height: auto;
}

.coaches-three.coaches-three--col-three .coaches-three-col:nth-child(3n) {
  border-right: none;
}

.favourite-item-list .favourite_item {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  overflow: hidden;
}

.favourite-item-list .favourite_item .companyName {
  font-size: 1.5rem;
}

.favourite-item-list .items_item .item_info {
  width: 64%;
}

.favourite.favourite-item-list .items_item .item_info {
  width: 76%;
}

.favourite-item-list .items_item {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  overflow: hidden;
  padding: 15px;
  border: 1px solid #eee;
  margin-bottom: 15px;
  height: 132px;
  overflow: auto;
}

.favourite.favourite-item-list .items_item {
  height: 201px;
}

.view-tabs .card {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border: 1px solid #eee;
}

.selected_option .fa {
  cursor: pointer;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .company.dropdown-menu {
      top: 80px !important;
      left: -20px !important;
    }

    .second-dropdown .company.dropdown-menu {
      top: 63px !important;
    }
  }
}

.dropdown-menu .dropdown-item .drop-item-img {
  vertical-align: top;
}

.company.dropdown-menu .dropdown-item {
  white-space: break-spaces;
}

.container.favourite-item-list {
  padding-bottom: 60px;
}

._slider-arrow {
  background-color: #c82638 !important;
  border-radius: 100% !important;
  color: white;
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.progresses {
  display: flex;
  align-items: center;
}

.progresses .line {
  width: 120px;
  height: 6px;
  background: #c82638;
}

.progresses .steps {
  display: flex;
  background-color: #c82638;
  color: #fff;
  font-size: 14px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

* {
  scrollbar-color: rgba(0, 0, 0, 0.2) hsla(0, 0%, 100%, 0.1);
  scrollbar-width: thin;
}

.list_grid ._clientList-div ._clCont ._name {
  font-size: 14px;
}

.class_appoint_tab .nav-tabs {
  border-bottom: 0px;
}

.class_appoint_tab .nav-tabs .nav-link.active {
  background: #c82638;
  color: #fff;
  border: 0 transparent;
}

.class_appoint_tab .nav-tabs .nav-link {
  text-transform: uppercase;
  background: transparent;
  border: 1px solid #eee;
  border-radius: 25px;
  margin-right: 10px;
  width: 140px;
  text-align: center;
  font-weight: 600;
  color: #333;
}

/* our services */

.single_feature {
  background: #fff none repeat scroll 0 0;
  margin-bottom: 30px;
  padding: 25px 26px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  cursor: pointer;
  border: 1px solid #eff2f7 !important;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;
  width: 100%;
}

.single_feature:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #c82638;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.single_feature:hover,
.single_feature:focus,
.single_feature:active {
  color: white;
}

.single_feature:hover:before,
.single_feature:focus:before,
.single_feature:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.single_feature i {
  border: 1px solid #e8e8e9;
  border-radius: 50%;
  color: #333;
  float: left;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  margin-right: 15px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 60px;
  z-index: 3;
  margin-top: 25px;
}

.single_feature:hover i {
  background: #fff;
  border: 1px solid #fff;
  color: #0b0861;
}

.single_feature h3 {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 400;
  margin-top: 0px;
  overflow: hidden;
}

.single_feature:hover h3 {
  color: #fff;
}

.single_feature span {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  cursor: pointer;
  border: 1px solid #eff2f7 !important;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;
}

.single_feature:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #c82638;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.single_feature:hover,
.single_feature:focus,
.single_feature:active,
.single_feature:hover label,
.single_feature:focus label,
.single_feature:active label,
.amazing_feature .single_feature.active label {
  color: white !important;
  border-color: #fff !important;
}

.single_feature label {
  color: #333 !important;
}

.single_feature:hover:before,
.single_feature:focus:before,
.single_feature:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.single_feature i {
  border: 1px solid #e8e8e9;
  border-radius: 50%;
  color: #333;
  float: left;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  margin-right: 15px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 60px;
  z-index: 3;
  margin-top: 25px;
}

.single_feature:hover i {
  background: #fff;
  border: 1px solid #fff;
  color: #0b0861;
}

.single_feature h3 {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 400;
  margin-top: 0px;
  overflow: hidden;
}

.single_feature:hover h3 {
  color: #fff;
}

.single_feature span {
  border-bottom: 1px dashed #ccc;
  display: block;
  margin: 15px auto 10px;
  width: 80px;
}

.single_feature p {
  margin-bottom: 0;
  overflow: hidden;
}

.amazing_feature .single_feature label {
  color: #333;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid #333;
  padding: 5px 20px;
  margin-top: 15px;
  cursor: pointer;
}

.select_coach .sidebar .side_icon {
  position: absolute;
  right: 19px;
  top: 21px;
  cursor: pointer;
}

.select_coach .page-wrap {
  overflow-x: hidden;
  font-family: "Helvetica Neue", sans-serif;
  line-height: 26px;
  position: relative;
}

.select_coach .page-content {
  position: relative;
  z-index: 0;
  padding: 10px 15px;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  transition: 0.6s;
  width: 100%;
}

.select_coach .toggle {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  font-size: 35px;
  color: black;
  z-index: 1;
}

.select_coach .toggle:hover {
  color: RGB(179, 2, 35);
}

.select_coach .sidebar {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0px;
  right: -416px;
  margin-top: 50px;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  transition: 0.6s;
  z-index: 0;
  width: 30%;
}

/* .select_coach #side:checked + .page-wrap .col-md-4 {
  width: auto;
} */
.select_coach #side {
  display: none;
}

.select_coach .sidebar .prof_pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background: #eee;
  margin-right: 10px;
}

.select_coach #side:checked+.page-wrap .sidebar {
  transform: translateX(-451px);
}

.select_coach #side:checked+.page-wrap .sidebar li {
  font-size: 16px;
}

.select_coach #side:checked+.page-wrap .page-content {
  width: 67%;
}

.select_coach .sidebar input:checked+.book_now {
  transform: translateY(40px);
  z-index: 1 !important;
}

.select_coach .page-content .amazing_feature .col-md-4 {
  width: 378px;
}

.select_coach .sidebar ._b-label input:checked~._clientList-div ._info {
  color: #333;
}

.select_coach .sidebar ._b-label input:checked~._clientList-div {
  background: transparent;
}

.select_coach .sidebar ._b-label input:checked~._clientList-div ._name {
  color: #333 !important;
}

/* .select_coach #side:checked + .page-wrap .col-md-4 {
  width: 50%;
} */
/* .select_coach .sidebar ._b-label input:checked ~ ._clientList-div {  
  margin-bottom: 39px;
}
.select_coach .sidebar ._b-label input:checked ~ ._clientList-div {
  margin-bottom: 39px;
}
.select_coach .sidebar button.book_now {
  position: absolute;
  bottom: 0 !important;
  z-index: -1 !important;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  transition: 0.6s;
} */
/* ._b-label input:checked ~ ._clientList-div .book_now {
  background: #fff !important;
  color: #333;
} */
.amazing_feature .single_feature.active {
  background: #c82638;
  color: #fff !important;
}

.amazing_feature .single_feature.active h3 {
  color: #fff;
}

/* our services */

/* Athlete Profile */

.space {
  margin: 0 20px;
}

.person-details table {
  width: 100%;
  max-width: 100%;
}

.person-details table tbody {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.longCont tr {
  display: table-row;
  min-width: 100px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 20px;
}

.person-details .shortCont tr {
  display: table-row;
  min-width: 100px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 20px;
}

.person-details .shortCont td:first-child {
  font-weight: 400;
  color: var(--text-sub-color);
  text-overflow: clip;
  text-align: left;
  width: 100%;
  line-height: 125%;
  letter-spacing: 1px;
}

.person-details .shortCont td:last-child {
  font-weight: 400;
  color: var(--text-primary-color);
  font-size: 20px;
  letter-spacing: 2px;
}

.btn img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.Home {
  perspective: 300px;
}

.info-btn {
  color: #fff;
  padding: 5px 25px;
  font-weight: bold;
  background-color: #027dbb;
  transform-style: preserve-3d;
}

.athlete.banner-container {
  position: relative;
  height: 430px;
  z-index: -1;
}

.athlete.banner-container .background_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);
}

.athlete.banner-container .CFLogoSvg_container {
  width: 180px;
  height: 180px;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 10;
  opacity: .6;
}

.athlete.banner-container .CFLogoSvg_logo {
  width: 100%;
  height: 60%;
  object-fit: contain;
}

.athlete.banner-container .CFLogoSvg_logo path {
  fill: var(--primary-color) !important;
  stroke: var(--primary-color) !important;
}

.athlete.banner-container .logo_para {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  width: 100%;
  text-wrap: nowrap;
}

.athlete .banner {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: top center;
  background-attachment: fixed;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
}

.athlete.banner-container::before {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 50%;
  height: 35px;
  background-color: #0c3970;
  z-index: 100;
  border-top: 5px solid #1151a0;
}

.athlete.banner-container::after {
  content: "";
  position: absolute;
  bottom: -30px;
  right: 0;
  width: 50%;
  height: 35px;
  background-color: #0c3970;
  z-index: 100;
  border-top: 5px solid #1151a0;
}

.profile-details {
  margin-top: 55px;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  min-height: 150px;
}

.performance_tab.card {
  border-radius: 6px;
}

.performance_tab .ant-collapse-header {
  background: #dddddd26;
}

.tablist-card.card {
  padding: 0px;
  margin-bottom: 0;
  box-shadow: none;
}

.tablist-card .list-grp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.tablist-card.card .card-body {
  padding: 0px;
  box-shadow: none;
}

.performance_tab .card-title {
  font-weight: 600;
  font-size: 1rem;
  color: #13293f;
  line-height: 1;
}

.performance_tab .main-container-tab.card-body {
  padding: 0;
  /* margin-top: 20px; */
}

.profile-details::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -8.3%;
  width: 100vw;
  height: 1px;
  background-color: #50627729;
}

.profile-details .left {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 75%;
}

.profile-details .profilePic {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  border: 0px solid #333;
  padding: 0;
  position: absolute;
  bottom: 20px;
  left: 20px;
  box-shadow: 2px 2px 10px #00000045;
}

.profile-details .avName {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-details .avName .proName {
  font-size: 4rem;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  text-align: center;
  color: #c82638;
  font-weight: 300;
}

.videoTab {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profile-details .profilePic img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profile-details .details {
  padding-left: 30%;
}

.profile-details .details .info {
  display: flex;
  align-items: center;
  gap: 30px;
}

.profile-details .details .heading {
  font-size: var(--h2);
  font-weight: 600;
  color: var(--text-primary-color);
  margin-bottom: 5px;
}

.profile-details .details .sub-head {
  font-size: var(--h4);
  color: var(--text-sub-color);
  font-weight: 400;
}

.profile-details .details .para {
  font-size: 16px;
  color: var(--text-primary-color);
  font-weight: 400;
  padding-bottom: 20px;
  text-decoration: none;
}

.profile-details .details a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.profile-details .details .para span {
  color: var(--text-primary-color);
  font-weight: 600;
}

.profile-details .right {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

/* Tabs */
/* .tab-cont {
  display: flex;
  justify-content: center;
  background-color: #fff;
} */

/* .tab-content {
  background-color: #f9fafb;
  padding: 20px;
} */

/* Card */
.athlete.card {
  background-color: #fff;
  border: 1px solid #edf0f2;
  border-radius: 4px;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.1);
  /* padding: 1rem; */
  margin: 0;
  transition: border-color 0.2s ease;
  overflow: hidden;
  
}
.athlete.highlighted-video {
  border: 2px solid #007bff;
  background-color: #f0f8ff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.1); 
  padding: 10px; 
  border-radius: 5px; 
}
.athlete .card-header {
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  border-bottom: transparent;
  padding: 1rem;
}

.athlete .card-header .card-logo {
  min-width: 3rem;
  min-height: 3rem;
  width: 3rem;
  height: 3rem;
  border-width: 3px;
  margin-right: 20px;
  border-radius: 50%;
  object-fit: cover;
}

.athlete .card-header .headTitle {
  font-weight: 300;
  font-size: 14px;
  color: #13293fa5;
  width: 90%;
}

.athlete .card-header .card-text {
  font-family: helvetica, arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #3a4d5f;
  line-height: 1.41667;
  color: #13293f66;
}

.athlete .card-header .headTitle b {
  font-weight: bold;
  color: #232a31;
  opacity: 1;
  margin-right: 0.015rem;
  -webkit-font-smoothing: antialiased;
  margin-right: 2px;
  font-size: 16px;
}

.athlete .card-header .headTitle .action {
  color: rgba(19, 41, 63, 0.8);
  font-weight: normal;
  font-size: 15px;
}

.athlete .card-header .headTitle .tags {
  color: rgba(19, 41, 63, 0.8);
  font-weight: normal;
  font-size: 15px;
}

.athlete .card-row {
  display: flex;
  justify-content: space-evenly;
}

.athlete .truncate {
  max-width: 200px;
  /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  /* Right-to-left text direction */
  text-align: left;
  /* Align text to the left */
}

/* history */
.person-details p {
  color: #13293f66;
  font-size: 1rem;
  margin-bottom: 0;
}

.person-details p span {
  color: var(--text-primary-color);
}

.team-history {
  padding-top: 1rem;
}

.team-history h4 {
  font-weight: 700;
  font-size: 1rem;
  color: #13293f;
  line-height: 1;
  margin-bottom: 1rem;
}

.list-grp .list-items {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
  height: 100px;
}

.main-container-tab .list-grp .list-items {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.list-grp .list-items .details h2 {
  font-family: helvetica, arial, sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: var(--text-primary-color);
  line-height: 1;
  margin-bottom: 0px;
}

.list-grp .list-items img {
  width: 70px;
  border-radius: 50%;
  height: 70px;
}

.list-grp .list-items .details p {
  margin-bottom: 0px;
  color: #3a4d5f;
}

.light {
  font-weight: 400;
  font-size: 14px;
  color: #13293f96;
  line-height: 1.41667;
  border-bottom: 0;
}

.athlete .card-body {
  position: relative;
  padding: 0;
}

.thumbnail {
  border-radius: 0;
}

.athlete .card-title {
  font-weight: 700;
  font-size: 1rem;
  color: #13293f;
  line-height: 1;
  margin-top: 1rem;
  padding: 0 20px;
  width: 90%;
}

.athlete .card-title.para {
  font-weight: 400;
  font-size: 0.875rem;
  color: #506277;
  line-height: 1.42857;
}

.athlete .share-btn {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border-top: 1px solid #edf0f2;
  border-radius: 0;
}

@media screen and (max-width: 1398px) {
  .profile-details .details {
    padding-left: 28%;
  }
}

@media screen and (max-width: 1198px) {
  .profile-details .details {
    padding-left: 32%;
  }
}

@media screen and (max-width: 992px) {
  .profile-details .profilePic {
    width: 120px;
    height: 120px;
    padding: 0;
    position: absolute;
    top: -80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .athlete.banner-container .background_image {
    object-position: right;
  }

  .profile-details .left {
    flex-direction: column;
    align-items: center;
  }

  .profile-details .details {
    padding-left: 0%;
    justify-content: center;
    align-items: center;
  }

  .profile-details .right {
    width: max-content;
  }
}

.performance-tab .nav-tabs {
  display: flex;
  justify-content: center;
}

.performance-tab .tab-content {
  background-color: #fff;
  padding-top: 30px;
}

/* Responsive  */
@media screen and (max-width: 1440px) {
  .athlete.banner-container {
    position: relative;
    height: 300px;
    z-index: -1;
  }
}

@media screen and (max-width: 1024px) {
  .athlete.banner-container {
    position: relative;
    height: 290px;
    z-index: -1;
    background-size: contain;
    background-attachment: scroll;
  }
}

@media screen and (max-width: 440px) {
  :root {
    --h2: 30px;
  }
}

@media screen and (max-width: 992px) {
  .athlete.banner-container {
    height: 300px;
    object-fit: cover;
    z-index: -1;
    object-position: right;
  }

  .athlete.banner-container .CFLogoSvg_container {
    width: 120px;
    height: 120px;
  }

  .athlete.banner-container .logo_para {
    font-size: 14px;
  }

  .profile-details {
    margin-top: 80px;
    z-index: 100;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    min-height: 150px;
    flex-direction: column;
    align-items: center;
  }

  .profile-details .avName .proName {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 1199px) {

  ._fB-mid.col-lg-8,
  ._fB-right.col-lg-4 {
    width: 100%;
  }

  ._fB-right ._filterGrp.text-right {
    text-align: center !important;
  }

  ._calenderSlider-div {
    margin-left: 0px;
  }

  ._dateRange p {
    margin-left: 0px;
  }

  ._calenderSlider-div ._dateRange {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .vertical-collpsed .main-content {
    margin-left: 70px;
  }

  .mobile-hide {
    width: 100%;
    margin-bottom: 0px;
  }

  .list-grp .list-items {
    margin-bottom: 20px;
  }

  .width-100 {
    width: 100%;
  }

  .profile-details .details .sub-head {
    margin-bottom: 20px;
  }
}

/* 
@media only screen and (max-width: 820px) {
  .athlete.banner-container {
    position: relative;
    height: 230px;
    z-index: -1;
    background-size: fill;
    background-attachment: scroll;
  }
}
@media only screen and (max-width: 768px) {
  .athlete.banner-container {
    position: relative;
    height: 200px;
    z-index: -1;
    background-size: fill;
    background-attachment: scroll;
  }
}
@media only screen and (max-width: 620px) {
  .athlete.banner-container {
    position: static;
    height: 500px;
    z-index: -1;
    background-size: fill;
    background-attachment: unset;
  }
} */

@media only screen and (max-width: 991px) {
  ._filterGrp.text-right {
    text-align: center !important;
  }

  ._calenderSlider-div {
    margin-left: 0px;
  }

  ._dateRange p {
    margin-left: 0px;
  }

  ._calenderSlider-div ._dateRange {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  ._dateBookingTable td {
    padding: 10px 5px;
  }

  ._dateBookingTable ._lgTxt {
    font-size: 20px;
    line-height: 22px;
  }

  ._calenderSlider-div ._dateRange {
    padding: 0;
    margin-bottom: 15px;
  }

  .d-sm-flex.flex-wrap.gap-1 .btn-group.mb-2 {
    margin-right: 5px;
  }

  #sidebar-menu {
    padding-top: 15px !important;
  }

  ._filterGrp.text-right {
    text-align: end;
  }

  ._dateRange p {
    margin-left: 0px;
  }

  .booked_table .booked-info {
    width: 100%;
  }

  ._dateBookingTable td {
    padding: 10px;
  }

  .credit-card-box {
    width: 100%;
  }

  .order_summery {
    padding: 0 !important;
    border: 0 !important;
  }

  .credit-card-box .order_summery .pack_item,
  .credit-card-box .order_summery h4 {
    padding-left: 0;
  }

  .account-pages .btn {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .table-responsive .left_td {
    display: none;
  }

  .external-login-hdr.company_logo {
    position: unset !important;
    transform: unset !important;
  }

  .favourite-search .form-control {
    width: 83%;
  }

  .favourite-search {
    margin-top: 8px;
  }

  .favourite-item-list .page-title-box {
    overflow: hidden;
  }

  .favourite-item-list .page-title-box h4 {
    float: left;
  }

  .favourite-item-list .page-title-box .page-title-right {
    float: right;
  }

  .company_logo .logo .logo-sm {
    width: 50px;
  }

  .external-login-hdr .name {
    display: none;
  }

  .external-login-hdr.company_logo .text-truncate {
    max-width: 110px;
  }

  .external-login-hdr.company_logo #company_dropdown {
    width: 21px;
    padding: 0;
  }

  .navbar-header .dropdown .dropdown-menu {
    width: 95%;
  }

  .external-login-hdr.company_logo .row.d-inline-block {
    display: flex !important;
  }

  .external-login-hdr.second-dropdown .dropdown-menu .dropdown-item.active::before {
    line-height: normal;
  }

  .select_coach .sidebar {
    width: 100% !important;
    background-color: #fff;
    right: -451px !important;
  }

  .select_coach #side:checked+.page-wrap .page-content {
    width: auto !important;
  }

  .list_grid ._clientLists ._clientList {
    width: 100% !important;
  }

  .progresses .line {
    width: 50px !important;
  }

  header .navbar-header .mult-address address strong {
    line-height: unset !important;
  }

  .company.dropdown-menu .dropdown-item {
    white-space: nowrap !important;
  }
}

@media only screen and (max-width: 480px) {
  .margin-top--small .inputStyle {
    width: 2rem !important;
    height: 2rem;
    margin: 0 5px;
    font-size: 1rem;
  }

  .vertical-collpsed .main-content {
    margin-left: 0 !important;
  }

  body.vertical-collpsed .vertical-menu {
    width: 250px !important;
  }

  body.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a span {
    display: inline-block;
    padding-left: 10px;
  }

  .company_logo .logo {
    margin-left: 17px;
  }

  .company_logo .logo .logo-sm {
    width: 30px;
  }

  .company_logo .logo span {
    font-size: 9px;
  }
}

/* Athlete Profile */

.shareBtnAthlete .modalHead {
  font-size: 20px;
  margin-bottom: 18px;
  font-weight: 600;
}

.shareBtnAthlete .clipboardLink {
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #ddd;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
}

.shareBtnAthlete .clipboardLink span {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.shareBtnAthlete .shareUl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0;
  gap: 20px;
}

.shareBtnAthlete .shareLI {
  list-style-type: none;
}

.modalHead {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
}

.shareBtnAthlete .shareLI a {
  display: flex;
  color: #333;
  font-size: 24px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: 0.5s ease-in-out;
}

.shareBtnAthlete .shareLI:hover a {
  border-color: #1877f2;
  color: #1877f2;
}

.shareBtnAthlete .shareHeading {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-sub-color);
  margin-bottom: 10px;
}

.shareBtnAthlete .ant-modal-close .anticon {
  font-size: 20px;
}
.highlighted-video {
  border: 2px solid #007bff;
  background-color: #f0f8ff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.1); 
  padding: 10px; 
  border-radius: 5px; 
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

