.ant_extra {
  font-family: "Poppins", sans-serif;
  &.ant-steps
    > .ant-steps-item.ant-steps-item-active
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background-color: #c82638;
    border-color: #c82638 !important;
  }
  &.ant-steps
    > .ant-steps-item.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background-color: #1ba717;
    border-color: #1ba717 !important;

    .ant-steps-icon {
      color: #ffffff;
    }
  }
  // &.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after
  &.ant-steps
    > .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #1ba717;
    height: 3px;
  }

  .ant-steps-item-description {
    max-width: 142px !important;
  }
}
