@import "../../assets/scss/colors";

.service-scroll {
  height: 200px;
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.card-session {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.selected-card {
  background-color: $primary-color;
  .txt {
    color: white;
  }
}
